<template>
    <cms-block v-bind="$props" @block-selected="selectBlock" no-padding>
        <div class="spacer" :style="style"/>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

// cms-block-spacer #hot-reload-debug
export default {
    name: `cms-block-spacer`,
    components: {CmsBlock},
    extends: CmsBlock,
    computed: {
        style() {
            return {
                height: this.block.size
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
